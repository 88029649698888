'use client';
import { FC, PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';
import { Container as MuiContainer, ContainerProps } from '@mui/material';

const StyledMuiContainer = styled(MuiContainer)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    padding: theme.spacing(0, 6),
  },
}));

interface IContainerProps extends PropsWithChildren<ContainerProps> {}

export const Container: FC<IContainerProps> = ({ children, ...props }) => {
  return (
    <StyledMuiContainer fixed maxWidth='xl' {...props}>
      {children}
    </StyledMuiContainer>
  );
};
