import { FC, useMemo, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller, useWatch, SubmitHandler } from 'react-hook-form';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/ru';
import { Box, Typography } from '@mui/material';
import { ILoadReq, PaymentMethodValueType } from 'types';
import { useGetMyLoad, useCreateLoad, useUpdateLoad } from 'hooks';
import {
  CountrySelect,
  PaymentSelect,
  TextField,
  Switch,
  DatePicker,
  Button,
} from 'UI';
import {
  PAYMENT_METHOD_VALUES,
  countryToFlag,
  getStickerOfPaymentMethod,
  notify,
} from 'utils';

import {
  StyledContainer,
  StyledFormPaper,
  StyledFormBody,
  StyledFormFooter,
  StyledFormItem,
} from './styledComponents';
import { PATHS } from 'app';
import { useTranslation } from 'react-i18next';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('ru');

export const CreateLoadForm: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const idOfTheLoad = useMemo(() => {
    const loadId = location.pathname.split('/').pop();
    return loadId === 'create-load' ? '' : loadId;
  }, [location.pathname]);
  const isEditMode = useMemo(() => !!idOfTheLoad, [idOfTheLoad]);

  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      countryFrom: '',
      addressFrom: '',
      countryTo: '',
      addressTo: '',
      loadType: '',
      weight: '',
      leaveDate: null,
      price: '',
      paymentType: 'ALL',
      advancePayment: false,
      advancePaymentAmount: '',
      phone: '',
      // telegramAccount: '',
      // additionalPhones: [],
      trackInfo: '',
      description: '',
    } as Partial<ILoadReq>,
  });
  const { data: load } = useGetMyLoad(idOfTheLoad);

  useEffect(() => {
    if (isEditMode && load) {
      reset({
        ...load,
        leaveDate: dayjs(load.leaveDate, 'DD.MM.YYYY')?.tz(),
      });
    }
  }, [idOfTheLoad, load, isEditMode, reset]);

  const advancePayment = useWatch({
    control,
    name: 'advancePayment',
    defaultValue: false, // Set the default value to false
  });
  const fromCountryFlag = useWatch({
    control,
    name: 'countryFrom',
    defaultValue: '',
  });
  const toCountryFlag = useWatch({
    control,
    name: 'countryTo',
    defaultValue: '',
  });
  const paymentType = useWatch({
    control,
    name: 'paymentType',
    defaultValue: 'ALL',
  }) as PaymentMethodValueType;

  const { isLoading: isCreateLoading, mutateAsync: createLoad } =
    useCreateLoad();
  const { isLoading: isUpdateLoading, mutateAsync: updateLoad } =
    useUpdateLoad();

  const getFormattedDate = (argDate: Date) => {
    const isoString = argDate.toISOString();
    const date = new Date(isoString);

    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}.${month}.${year}`;
  };

  const submitForm: SubmitHandler<Partial<ILoadReq>> = data => {
    // @ts-ignore
    const formattedDate = getFormattedDate(data.leaveDate as Date);
    if (isEditMode) {
      ReactGA.event({
        category: 'User',
        action: 'UpdateLoad',
      });
      // @ts-ignore
      updateLoad({
        ...data,
        leaveDate: formattedDate,
      })
        .then(() => {
          notify('success', t('Load updated successfully'));
          navigate(PATHS.myLoads);
        })
        .catch(() => {});
      return;
    }
    ReactGA.event({
      category: 'User',
      action: 'CreateLoad',
    });
    // @ts-ignore
    createLoad({
      ...data,
      leaveDate: formattedDate,
      // FIXME: FIX THIS LATER
      priceCurrency: 'USD',
      advancePaymentCurrency: 'USD',
      active: true,
    })
      .then(() => {
        notify('success', t('Load created successfully'));
        navigate(PATHS.myLoads);
      })
      .catch(() => {});
  };

  const resetFormAndClose = () => {
    reset();
    navigate(-1);
  };

  return (
    <StyledContainer>
      <StyledFormPaper>
        <StyledFormBody>
          <Box className='flex'>
            <StyledFormItem>
              <Typography variant='body1' variantMapping={{ body1: 'label' }}>
                {t('Country (From)')}
                <span className='required'>*</span>
                {countryToFlag(fromCountryFlag)}
              </Typography>
              <CountrySelect
                control={control}
                defaultValue={isEditMode ? load?.countryFrom : ''}
                placeholder={t('Uzbekistan', { ns: 'countries' })}
                name='countryFrom'
                required
              />
            </StyledFormItem>
            <StyledFormItem>
              <Typography variant='body1' variantMapping={{ body1: 'label' }}>
                {t('City (From)')}
                <span className='required'>*</span>
              </Typography>
              <Controller
                control={control}
                name='addressFrom'
                rules={{
                  required: t('This field is required'),
                }}
                render={({ field, fieldState }) => {
                  const { name, onChange, onBlur, ref, value } = field;
                  const { error } = fieldState;
                  return (
                    <TextField
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      value={value}
                      error={!!error}
                      errorMessage={error?.message}
                      placeholder='Tashkent'
                      required
                    />
                  );
                }}
              />
            </StyledFormItem>
          </Box>
          <Box className='flex'>
            <StyledFormItem>
              <Typography variant='body1' variantMapping={{ body1: 'label' }}>
                {t('Country (To)')}
                <span className='required'>*</span>
                {countryToFlag(toCountryFlag)}
              </Typography>
              <CountrySelect
                control={control}
                defaultValue={isEditMode ? load?.countryTo : ''}
                placeholder={t('Russia', { ns: 'countries' })}
                name='countryTo'
                required
              />
            </StyledFormItem>
            <StyledFormItem>
              <Typography variant='body1' variantMapping={{ body1: 'label' }}>
                {t('City (To)')}
                <span className='required'>*</span>
              </Typography>
              <Controller
                control={control}
                name='addressTo'
                rules={{
                  required: t('This field is required'),
                }}
                render={({ field, fieldState }) => {
                  const { name, onChange, onBlur, ref, value } = field;
                  const { error } = fieldState;
                  return (
                    <TextField
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      value={value}
                      error={!!error}
                      errorMessage={error?.message}
                      placeholder='Moscow'
                      required
                    />
                  );
                }}
              />
            </StyledFormItem>
          </Box>
          <StyledFormItem>
            <Typography variant='body1' variantMapping={{ body1: 'label' }}>
              {t('Load type')} 📦
            </Typography>
            <Controller
              control={control}
              name='loadType'
              render={({ field, fieldState }) => {
                const { name, onChange, onBlur, ref, value } = field;
                const { error } = fieldState;
                return (
                  <TextField
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputRef={ref}
                    value={value}
                    error={!!error}
                    errorMessage={error?.message}
                    placeholder={t('Potato')}
                  />
                );
              }}
            />
          </StyledFormItem>
          <StyledFormItem>
            <Typography variant='body1' variantMapping={{ body1: 'label' }}>
              {t('Load weight')} 🧱
            </Typography>
            <Controller
              control={control}
              name='weight'
              render={({ field, fieldState }) => {
                const { name, onChange, onBlur, ref, value } = field;
                const { error } = fieldState;
                return (
                  <TextField
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputRef={ref}
                    value={value}
                    error={!!error}
                    errorMessage={error?.message}
                    placeholder={t('10 tonna')}
                  />
                );
              }}
            />
          </StyledFormItem>
          <StyledFormItem>
            <Typography variant='body1' variantMapping={{ body1: 'label' }}>
              {t('Leave date')}
              <span className='required'>*</span> 📆
            </Typography>
            <Controller
              control={control}
              name='leaveDate'
              rules={{
                required: t('This field is required'),
              }}
              render={({ field, fieldState }) => {
                const { name, onChange, ref, value } = field;
                const { error } = fieldState;
                return (
                  <DatePicker
                    name={name}
                    onChange={onChange}
                    value={isEditMode ? dayjs(value) : null}
                    ref={ref}
                    error={!!error}
                    errorMessage={error?.message}
                    disablePast
                  />
                );
              }}
            />
          </StyledFormItem>
          <StyledFormItem>
            <Typography variant='body1' variantMapping={{ body1: 'label' }}>
              {t('Price')}
              💰
            </Typography>
            <Controller
              control={control}
              name='price'
              render={({ field, fieldState }) => {
                const { name, onChange, onBlur, ref, value } = field;
                const { error } = fieldState;
                return (
                  <TextField
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputRef={ref}
                    value={value}
                    error={!!error}
                    errorMessage={error?.message}
                    placeholder='2000'
                    InputProps={{
                      startAdornment: <Typography mr={-2}>$</Typography>,
                    }}
                    required
                  />
                );
              }}
            />
          </StyledFormItem>
          <StyledFormItem>
            <Typography variant='body1' variantMapping={{ body1: 'label' }}>
              {t('Payment Method')} {getStickerOfPaymentMethod(paymentType)}
            </Typography>
            <PaymentSelect
              control={control}
              defaultValue={PAYMENT_METHOD_VALUES.ALL}
            />
          </StyledFormItem>
          <Box className='flex flex-end'>
            <StyledFormItem>
              <Controller
                control={control}
                name='advancePayment'
                render={({ field }) => {
                  const { onChange, value } = field;
                  return (
                    <Switch
                      label={t('Advance payment')}
                      checked={!!value}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </StyledFormItem>
            {advancePayment && (
              <StyledFormItem>
                <Typography variant='body1' variantMapping={{ body1: 'label' }}>
                  {t('Advance payment amount')} 🔥
                </Typography>
                <Controller
                  control={control}
                  name='advancePaymentAmount'
                  render={({ field }) => {
                    const { name, onChange, onBlur, ref, value } = field;
                    return (
                      <TextField
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        inputRef={ref}
                        value={value}
                        placeholder='2000'
                        InputProps={{
                          startAdornment: <Typography mr={1}>$</Typography>,
                        }}
                      />
                    );
                  }}
                />
              </StyledFormItem>
            )}
          </Box>
          <StyledFormItem>
            <Typography variant='body1' variantMapping={{ body1: 'label' }}>
              {t('Phone')}
              <span className='required'>*</span> 📞
            </Typography>
            <Controller
              control={control}
              name='phone'
              // rules={{
              //   required: t('This field is required'),
              // }}
              render={({ field, fieldState }) => {
                const { name, onChange, onBlur, ref, value } = field;
                const { error } = fieldState;
                return (
                  <TextField
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputRef={ref}
                    value={value}
                    error={!!error}
                    errorMessage={error?.message}
                    placeholder='+998 99 999 99 99'
                    required
                  />
                );
              }}
            />
          </StyledFormItem>
          {/* <StyledFormItem>
            <Typography variant='body1' variantMapping={{ body1: 'label' }}>
              {t('Telegram account')}
            </Typography>
            <Controller
              control={control}
              name='telegramAccount'
              render={({ field, fieldState }) => {
                const { name, onChange, onBlur, ref, value } = field;
                const { error } = fieldState;
                return (
                  <TextField
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputRef={ref}
                    value={value}
                    error={!!error}
                    errorMessage={error?.message}
                    placeholder=''
                    // required
                    InputProps={{
                      startAdornment: <Typography mr={-2}>@</Typography>,
                    }}
                  />
                );
              }}
            />
          </StyledFormItem> */}
          <StyledFormItem>
            <Typography variant='body1' variantMapping={{ body1: 'label' }}>
              {t('Car requirements')} 🚛
            </Typography>
            <Controller
              control={control}
              name='trackInfo'
              render={({ field }) => {
                const { name, onChange, onBlur, ref, value } = field;
                return (
                  <TextField
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputRef={ref}
                    value={value}
                    placeholder={t('Car requirements')}
                    multiline
                    rows={4}
                  />
                );
              }}
            />
          </StyledFormItem>
          <StyledFormItem>
            <Typography variant='body1' variantMapping={{ body1: 'label' }}>
              {t('Comments')} ❗️
            </Typography>
            <Controller
              control={control}
              name='description'
              render={({ field }) => {
                const { name, onChange, onBlur, ref, value } = field;
                return (
                  <TextField
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputRef={ref}
                    value={value}
                    placeholder={t('Comments')}
                    multiline
                    rows={4}
                  />
                );
              }}
            />
          </StyledFormItem>
        </StyledFormBody>
        <StyledFormFooter>
          <Button
            variant='outlined'
            color='primaryBlue'
            size='large'
            onClick={resetFormAndClose}
          >
            {t('Cancel')}
          </Button>
          <Button
            variant='contained'
            color='primaryBlue'
            size='large'
            // @ts-ignore
            onClick={handleSubmit(submitForm)}
            isLoading={isCreateLoading || isUpdateLoading}
            disabled={isCreateLoading || isUpdateLoading}
          >
            {t('Save')}
          </Button>
        </StyledFormFooter>
      </StyledFormPaper>
    </StyledContainer>
  );
};
