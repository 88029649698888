import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover, Typography } from '@mui/material';
import { UzbekIcon, RussianIcon } from 'assets';
import { theme } from 'utils';

import {
  StyledLanguageSwitcherBox,
  StyledLanguagesList,
  StyledLanguageButton,
} from './styledComponents';

interface ILang {
  code: string;
  label: string;
  icon: JSX.Element;
}

const LANGUAGES: Array<ILang> = [
  { code: 'uz', label: 'Uz', icon: <UzbekIcon /> },
  { code: 'ru', label: 'Ру', icon: <RussianIcon /> },
];

export const LanguageSwitcher: FC = () => {
  const { t, i18n } = useTranslation();
  const [activeLang, setActiveLang] = useState<string>(LANGUAGES[0].code);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowPopover(true);
  };

  const closePopover = () => {
    setShowPopover(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    setActiveLang(i18n.language);
  }, [i18n.language]);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('lang', language);
    setActiveLang(language);
    closePopover();
  };

  return (
    <StyledLanguageSwitcherBox>
      <Popover
        id='languageSwitcher'
        anchorEl={anchorEl}
        open={showPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorPosition={{ top: 500, left: 500 }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={closePopover}
      >
        <StyledLanguagesList>
          {LANGUAGES.map(language => (
            <StyledLanguageButton
              key={language.code}
              variant='text'
              size='large'
              onClick={() => changeLanguage(language.code)}
              className='popoverButton'
            >
              {language.icon}
              <Typography color={theme.palette.black} variant='body1'>
                {language.label}
              </Typography>
            </StyledLanguageButton>
          ))}
        </StyledLanguagesList>
      </Popover>
      <StyledLanguageButton variant='text' onClick={openPopover} size='large'>
        {LANGUAGES.find(lang => lang.code === activeLang)?.icon}
        <Typography color={theme.palette.black} variant='body1'>
          {LANGUAGES.find(lang => lang.code === activeLang)?.label}
        </Typography>
      </StyledLanguageButton>
    </StyledLanguageSwitcherBox>
  );
};
