import { Box } from '@mui/material';
import { PATHS } from 'app';
import { Logo } from 'assets';
import { Link } from 'react-router-dom';
import { Container } from 'UI';

import {
  StyledHeader,
  StyledLeftSide,
  StyledRightSide,
} from './styledComponents';
import { LanguageSwitcher } from './LanguageSwitcher';
import { Profile } from './Profile';

export const Header = () => {
  return (
    <StyledHeader>
      <Container className='container'>
        <StyledLeftSide>
          <Link to={PATHS.home}>
            <Logo />
          </Link>
        </StyledLeftSide>
        <StyledRightSide>
          <LanguageSwitcher />
          <Box className='logo'>
            <Link to={PATHS.home}>
              <Logo />
            </Link>
          </Box>
          <Profile />
        </StyledRightSide>
      </Container>
    </StyledHeader>
  );
};
