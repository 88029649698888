import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import uz from 'locales/uz/translation.json';
import ru from 'locales/ru/translation.json';
import countriesUz from 'locales/uz/countries.json';
import countriesRu from 'locales/ru/countries.json';
import errorsUz from 'locales/uz/errors.json';
import errorsRu from 'locales/ru/errors.json';

const resources = {
  uz: {
    translation: uz,
    countries: countriesUz,
    errors: errorsUz,
  },
  ru: {
    translation: ru,
    countries: countriesRu,
    errors: errorsRu,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'uz',
  lng: localStorage?.getItem('lang') || 'uz',
  interpolation: {
    escapeValue: false,
  },
  supportedLngs: ['uz', 'ru'],
});

export default i18n;
